<template>
    <div class="certificateServices">
        <!-- 头部组件 -->
        <headPage />
        <!-- 顶部图片 -->
        <div class="top-img" @click="dialogVisible=true"></div>
        <div class="block-item1">
            <div class="block-item1-wrap">
                <div class="left">
                    <div class="title">
                        全渠道营销，提高招生效率
                    </div>
                    <div class="detail">
                        精准营销和全渠道获客，实现业务增长，<br>提供裂变海报、分享、秒杀、限时折扣、优惠券等营销工具。
                    </div>
                </div>
                <div class="right">
                    
                </div>
            </div>
        </div>
        <div class="block-item2">
            <div class="block-item2-wrap">
                <div class="left">
                    
                </div>
                <div class="right">
                    <div class="title">
                        多终端支持，提升学习体验
                    </div>
                    <div class="detail">
                        支持网页端、App端、微信跨平台学习，<br>海量精品课程，满足用户培训学习需求。
                    </div>
                </div>
            </div>
        </div>
        <div class="block-item3">
            <div class="block-item3-wrap">
                <div class="left">
                    <div class="title">
                        模拟练习，助力通关
                    </div>
                    <div class="detail">
                        千余套权威题库资源，高效组卷抽题，分项练习、<br> 错题复习、精准分析，多手段巩固学习效果。
                    </div>
                </div>
                <div class="right">
                    
                </div>
            </div>
        </div>
        <div class="block-item4">
            <div class="block-item4-wrap">
                <div class="left">
                    
                </div>
                <div class="right">
                    <div class="title">
                        云端考试，稳定高效
                    </div>
                    <div class="detail">
                        全流程考务保障，支持智能监考、<br> AI防作弊、智能阅卷等功能。
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部组件 -->
        <bottomPage />
        <!-- 对话框 -->
        <el-dialog
            title=""
            class="popUp"
            width="600px"
            top="10%"
            :modal="false"
            :visible.sync="dialogVisible"
            @closed="dialogClosed"
            >
            <div class="contactTeacherPopup">
                <div class="dialogTop">
                <img src="@/assets/mukeCooperation/icon15.png" alt="" />
                    <span>合作咨询</span>
                </div>
                <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="公司全称">
                        <el-input @focus="clearValidate('companyName')" placeholder="以营业执照为准" maxlength="50" show-word-limit v-model="ruleForm.companyName"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名">
                        <el-input @focus="clearValidate('contactsName')" placeholder="请输入姓名" v-model="ruleForm.contactsName"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="contactsMobile">
                        <el-input @focus="clearValidate('contactsMobile')" placeholder="请输入手机号" v-model="ruleForm.contactsMobile" @input="filter('contactsMobile')"></el-input>
                    </el-form-item>
                    <div class="flexcc" style="margin-top:40px;">
                        <el-button class="bgc-bv" style="width:180px;" @click="submit">提 交</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import headPage from "../wrongAuthenticatePage/headPage.vue";
    import bottomPage from "../wrongAuthenticatePage/bottomPage.vue";
    export default {
        name:"certificateServices",
        components:{
            headPage,
            bottomPage
        },
        data(){
            return{
                // 对话框可见性
                dialogVisible:false,
                // 表单数据
                ruleForm:{
                    companyName:"", // 公司全称
                    contactsMobile:"", // 姓名
                    contactsName:"",// 联系人姓名
                },
                // 表单规则
                rules:{
                    companyName:[
                        {required:true,message:"请填写公司全称",trigger:"blur"}
                    ],
                    contactsName:[
                        {required:true,message:"请填写姓名",trigger:"blur"}
                    ],
                    contactsMobile:[
                        {required: false,validator: this.$validatePhone,trigger:"blur"}
                    ]
                }
            }
        },
        methods:{
            // 过滤非法字符
            filter(key){
                const {ruleForm}=this;
                ruleForm[key]=ruleForm[key].replace(/[^\d]/,"");
            },
            // 清除校验结果
            clearValidate(key){
                this.$refs.ruleForm.clearValidate(key);
            },
            // 对话框关闭以后重置表单
            dialogClosed(){
                this.$refs.ruleForm.resetFields();
                this.ruleForm={
                    companyName:"", // 公司全称
                    contactsName:"", // 姓名
                    contactsMobile:"" // 手机号
                }
            },
            // 提交
            submit(){
                const {ruleForm}=this;
                let flag=true;
                Object.keys(ruleForm).forEach(key=>{
                    if(ruleForm[key].trim()==="") return flag=false;
                });
                if(!flag) return this.$message.info("请先完善信息再提交!");
                this.$refs.ruleForm.validate(valid=>{
                    if(valid){
                        this.$post("/biz/cooperate/consulting/insert",ruleForm).then(()=>{
                            this.$message.success("提交成功，请等待平台审核");
                            this.dialogVisible=false;
                        });
                    }else{
                        return false;
                    }
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    .certificateServices{
        height: 100vh;
        overflow-y: scroll;
        .top-img{
            height: 24.6875rem;
            background-image: url("../../assets/loginIndex/certificateServices-img.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }
        .block-item1{
            height: 32.8125rem;
            background-color: white;
            .block-item1-wrap{
                width: 73rem;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                .left{
                    padding-left: 1.0625rem;
                    .title{
                        font-size: 1.4375rem;
                        font-weight: bold;
                        color: #333333;
                        margin-bottom: .625rem;
                    }
                    .detail{
                        font-size: .9375rem;
                        font-weight: 400;
                        color: #666666;
                    }
                }
                .right{
                    height: 25.4375rem;
                    width: 30.1875rem;
                    background-image: url("../../assets/loginIndex/zsfwct1.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
        .block-item2{
            height: 32.8125rem;
            background-color: #F9F9F9;
            .block-item2-wrap{
                width: 73rem;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                .right{
                    text-align: right;
                    padding-right: 1.0625rem;
                    .title{
                        font-size: 1.4375rem;
                        font-weight: bold;
                        color: #333333;
                        margin-bottom: .625rem;
                    }
                    .detail{
                        font-size: .9375rem;
                        font-weight: 400;
                        color: #666666;
                    }
                }
                .left{
                    height: 20.3125rem;
                    width: 35rem;
                    background-image: url("../../assets/loginIndex/zsfwct2.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
        .block-item3{
            height: 32.8125rem;
            background-color: white;
            .block-item3-wrap{
                width: 73rem;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                .left{
                    padding-left: 1.0625rem;
                    .title{
                        font-size: 1.4375rem;
                        font-weight: bold;
                        color: #333333;
                        margin-bottom: .625rem;
                    }
                    .detail{
                        font-size: .9375rem;
                        font-weight: 400;
                        color: #666666;
                    }
                }
                .right{
                    height: 24.1875rem;
                    width: 31.0625rem;
                    background-image: url("../../assets/loginIndex/zsfwct3.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
        .block-item4{
            height: 32.8125rem;
            background-color: #F9F9F9;
            .block-item4-wrap{
                width: 73rem;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                .right{
                    text-align: right;
                    padding-right: 1.0625rem;
                    .title{
                        font-size: 1.4375rem;
                        font-weight: bold;
                        color: #333333;
                        margin-bottom: .625rem;
                    }
                    .detail{
                        font-size: .9375rem;
                        font-weight: 400;
                        color: #666666;
                    }
                }
                .left{
                    height: 20.9375rem;
                    width: 38.9375rem;
                    background-image: url("../../assets/loginIndex/zsfwct4.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
        .dialog-footer{
            .el-button:nth-child(2){
                background: #2B77FD;
            }
        }
    }
    .certificateServices {
        /deep/.el-dialog__wrapper {
            z-index: 9999 !important;
        }
        /deep/.el-dialog {
            background-color: transparent;
            border-radius: 20px;
        }
        /deep/.el-dialog__header {
            background: #fff;
            border-top-left-radius: 20px !important;
            border-top-right-radius: 20px !important;
            padding: 20px 20px 10px;
            .el-dialog__title {
                color: #333;
                font-size: 20px;
            }
            .el-dialog__headerbtn {
                top: 38px;
                .el-dialog__close {
                    color: #333;
                }
            }
            .el-dialog__headerbtn:focus .el-dialog__close,
            .el-dialog__headerbtn:hover .el-dialog__close {
                color: #333;
            }
        }
        /deep/.el-dialog__body {
            background: #fff;
            border-bottom-left-radius: 20px !important;
            border-bottom-right-radius: 20px !important;
            padding: 0 30px;
        }
        .contactTeacherPopup {
            padding-bottom: 57px;
            .dialogTop {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 45px;
                span {
                    font-size: 23px;
                    color: #333;
                }
            }
        }
    }
</style>